import request from "@/utils/request";
const controller = "/CourseLearning";

export function saveLearningLog(data) {
  return request({
    url: `${controller}/SaveLearningLog`,
    method: "post",
    data
  });
}

export function getCourseLearningInfo(id) {
  return request({
    url: `${controller}/GetInfo`,
    method: "get",
    params: {
      seriesId: id
    }
  });
}
