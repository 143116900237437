<template>
  <div class="div-container">
    <div class="">
      <!-- <div class="g-wrap" style="margin: 0 auto;">
        <content-header :title-list="titleList"></content-header>
      </div> -->
      <div class="detail-top">
        <div class="g-wrap">
          <div
            class="course-img"
            :style="`background-image: url(${course.CoverImageFullPath})`"
          >
            <!-- <div class="course-cur-bg"></div>
            <div class="course-living-wrap" @click="handleGoLivingRoom">
              <i class="el-icon-video-play course-cur-icon"></i>
              <div class="living-wrap" title="点击进入直播间">
                <img src="/static/images/living.gif" alt="" />
                <span class="course-cur-text">直播进行中</span>
              </div>
            </div> -->
            <vue-aliplayer-v2
              v-if="isPlayVideo"
              :source="source"
              ref="VueAliplayerV2"
              :options="options"
              @play="playVideo"
              @pause="pauseVideo"
              @ended="endedVideo"
            />
          </div>
          <div class="course-right">
            <div>
              <span class="right-row main-title-l">{{ course.Name }}</span>
              <div class="right-row  mt-16">
                <span class="remark-text"
                  >{{ course.SignupCount }}人已报名</span
                >
                <template v-if="course.LessonCount">
                  <span class="remark-text mr-8 ml-8">|</span>
                  <span class="remark-text"
                    >共{{ course.LessonCount }}节课</span
                  >
                </template>
                <template v-if="course.OwnerName">
                  <span class="remark-text mr-8 ml-8">|</span>
                  <span class="remark-text">{{ course.OwnerName }}</span>
                </template>
              </div>
            </div>
            <!-- <span v-if="course.IsCharge" class="right-row price-text">¥{{ course.Price }}</span>
            <span v-else class="right-row price-text">免费</span> -->
            <div class="right-row  mt-20">
              <!-- <el-button v-if="course.IsSignup" type="primary" class="main-button">已报名</el-button> -->
              <el-button
                v-if="signUpObject.show"
                type="primary"
                class="main-button"
                :loading="loading"
                @click="buy"
                >{{ signUpObject.name }}</el-button
              >
              <!-- <el-button type="primary" class="main-button" plain icon="el-icon-share" @click="">分享</el-button> -->
            </div>
          </div>
        </div>
      </div>

      <div class="btf-box">
        <div class="g-wrap detail-bottom">
          <div class="tab-wrap">
            <div class="tab-header">
              <div
                :class="`tab-item ${activeId == 1 ? 'active' : ''}`"
                @click="activeId = 1"
              >
                <span class="tab-title">课程简介</span>
              </div>
              <div
                :class="`tab-item ${activeId == 2 ? 'active' : ''}`"
                @click="activeId = 2"
              >
                <span class="tab-title">课程目录</span>
              </div>
            </div>
            <div class="tab-content">
              <div
                class="item-content"
                v-show="activeId == 1"
                v-html="course.Introduction"
              ></div>
              <div class="item-content" v-show="activeId == 2">
                <el-collapse v-if="course.ListCatalog" v-model="listCatalogId">
                  <el-collapse-item
                    v-for="(item, i) in course.ListCatalog"
                    :key="i"
                    :name="item.Id"
                  >
                    <template slot="title">
                      <div>
                        <span class="item-catalog">{{ item.Name }}</span>
                      </div>
                    </template>
                    <div
                      v-for="(c, j) in item.ListMaterial"
                      :key="j"
                      :class="
                        course.TrainTypeId == '2'
                          ? 'xianxia-course'
                          : 'small-course'
                      "
                      @click="playCourse(c)"
                    >
                      <div>
                        <span class="course-index">{{ j + 1 }}</span>
                        <span class="name-text">{{ c.Name }}</span
                        ><span class="info-span-b">|</span
                        ><span class="name-text">{{ c.Type }}</span>
                      </div>
                      <div class="ml-28">
                        <i
                          :class="
                            `fa ${
                              c.Type == 'mp4'
                                ? 'fa-youtube-play'
                                : 'fa-file-text'
                            } text-icon`
                          "
                        ></i>
                        <span class="info-text-c9">{{ c.LiveType }}</span>
                        <span class="info-span">|</span>
                        <span
                          class="info-text-c9"
                          v-if="course.LearningRecordId"
                          >已学{{
                            (course.LearnedTime / course.TotalLength)
                              | percentage2
                          }}</span
                        >
                        <span class="info-text-c9" v-else>未学习</span>
                      </div>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </div>
          </div>

          <div class="course-lecturer" v-if="course.TrainTypeId == '1'">
            <div class="lecturer-header">
              <span class="header-text">
                讲师介绍
              </span>
            </div>
            <div class="lecturer-list">
              <el-carousel
                :interval="5000"
                arrow="always"
                class="carousel-box"
                height="400px"
              >
                <el-carousel-item
                  v-for="lecturer in course.ListLecturer"
                  :key="lecturer.Id"
                  class="lecturer-item"
                >
                  <img :src="lecturer.Photo" alt="" class="lecturer-img" />
                  <span class="lecturer-name">{{ lecturer.RealName }}</span>
                  <span class="lecturer-introduction">{{
                    lecturer.Introduction
                  }}</span>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getInfo } from "@/api/courseseries";
import { createEntity } from "@/api/coursesignup";
import { saveLearningLog, getCourseLearningInfo } from "@/api/courselearning";
import ContentHeader from "@/components/Common/ContentHeader";
import VueAliplayerV2 from "vue-aliplayer-v2";
import { mapGetters } from "vuex";
export default {
  components: {
    ContentHeader,
    VueAliplayerV2
  },
  data() {
    return {
      course: {},
      titleList: [
        {
          text: "课程资源",
          isCur: false,
          link: ""
        },
        {
          text: " 职业能力导向课程",
          isCur: true,
          link: ""
        }
      ],
      options: {
        // source:'//player.alicdn.com/video/aliyunmedia.mp4',
        isLive: false //切换为直播流的时候必填
        // autoplay: false
        // format: 'm3u8'  //切换为直播流的时候必填
      },
      source: "",
      isPlayVideo: false,
      currentCourse: "",
      learnedTime: 0,
      learnedTimeOut: 0,
      initialTime: 0, // 视频初始播放位置

      activeId: 1,
      listCatalogId: [],
      liveroomUrl: "",
      titleName: "",
      loading: false,
      userExpand: this.$store.getters.userExpand
    };
  },
  computed: {
    ...mapGetters(["employee", "userId"]),
    signUpObject({ course = {}, employee = [], userId }) {
      const obj = {
        show: false
      };
      const studentObject = employee.find(
        item => !item.IsTeacher && !item.IsAdmin
      );
      if (!studentObject) {
        return obj;
      }
      obj.show = userId ? true : false;
      obj.name = course.IsSignup ? "已报名" : "报名";
      return obj;
    }
  },
  filters: {
    fileTypeFilter(type) {
      let path = "/static/images/pdf.png";
      switch (type) {
        case "docx":
        case "doc":
          path = "/static/images/word.png";
          break;
        case "ppt":
        case "pptx":
          path = "/static/images/ppt.png";
          break;
        case "png":
        case "jpg":
          path = "/static/images/picture.png";
          break;
        case "xlsx":
        case "xls":
          path = "/static/images/excel.png";
          break;
        default:
          break;
      }
      return path;
    }
  },
  created() {
    this.init();
  },
  beforeDestroy() {
    if (this.isPlayVideo) {
      clearInterval(this.learnedTimeOut);
      this.updateLearningLog();
    }
  },
  methods: {
    init() {
      var that = this;
      getInfo(that.$route.query.id).then(res => {
        that.course = res.Result;
        that.course.ListCatalog.map(p => {
          p.open = true;
        });
        // 查询学习记录
        if (!this.$store.getters.userId) {
          return;
        }
        getCourseLearningInfo(that.$route.query.id).then(res => {
          if (res.Result) {
            let CurrentMaterialId = res.Result.CurrentMaterialId;
            that.course.ListCatalog.map(p => {
              p.ListMaterial.map(n => {
                if (n.Id == CurrentMaterialId) {
                  that.currentCourse = n;
                  if (n.Type == "mp4") {
                    that.source = n.FileUrlFullPath;
                    // that.isPlayVideo = true;
                    that.initialTime = n.CurrentPosition
                      ? n.CurrentPosition
                      : 0;
                  }
                }
              });
            });
          }
        });
      });
    },
    // 播放课程
    playCourse(c) {
      if (!this.userId) {
        this.$message.warning("请先登录");
        return;
      }
      // 判断是否购买课程
      if (this.course.IsSignup || !this.signUpObject.show) {
        this.currentCourse = c;
        if (c.Type == "mp4") {
          this.source = c.FileUrlFullPath;
          this.initialTime = c.CurrentPosition ? c.CurrentPosition : 0;
          // this.$refs.VueAliplayerV2.play();
          this.isPlayVideo = true;
        } else if (c.Type == "pdf") {
          //
        } else if (c.Type == "jpg" || c.Type == "png" || c.Type == "jpeg") {
          // 预览图片
          this.$message.warning("暂不支持此格式");
        } else {
          this.$message.warning("暂不支持此格式");
        }
      } else {
        this.$message.warning("请先报名课程");
      }
    },

    // 报名（购买）课程
    buy() {
      if (this.course.IsSignup) {
        return;
      }
      if (this.course.Price) {
        // 收费课程
      } else {
        var params = {
          UserId: this.$store.getters.user.Id,
          SeriesId: this.course.Id,
          SingnupTime: new Date(),
          IsPay: true,
          OrderNo: null,
          PayAmount: 0,
          IsCertify: false,
          CertificateUrl: null,
          Status: 0
        };
        // console.log(this.$store.getters.user.Id);
        createEntity(params).then(res => {
          if (res.IsSuccess) {
            this.$message.success("报名成功");
            this.course.IsSignup = true;
          }
        });
      }
    },
    // 更新学习记录
    updateLearningLog() {
      let currentPosition = this.$refs.VueAliplayerV2.getCurrentTime();
      var params = {
        SeriesId: this.course.Id,
        CurrentMaterialId: this.currentCourse.Id,
        LearnedTime: this.learnedTime,
        CurrentPosition: parseInt(currentPosition)
      };
      saveLearningLog(params).then(res => {
        if (res.IsSuccess) {
          this.learnedTime = 0;
        }
      });
    },
    // 视频开始/继续播放
    playVideo() {
      var that = this;
      if (that.currentCourse.CurrentPosition) {
        that.$refs.VueAliplayerV2.seek(that.currentCourse.CurrentPosition);
      }
      that.learnedTimeOut = setInterval(() => {
        that.learnedTime += 1;
      }, 1000);
    },
    // 暂停视频播放
    pauseVideo() {
      var that = this;
      clearInterval(this.learnedTimeOut);
      this.updateLearningLog();
      that.course.ListCatalog.map(p => {
        p.ListMaterial.map(n => {
          if (n.Id == this.currentCourse.Id) {
            if (n.Type == "mp4") {
              n.CurrentPosition = this.$refs.VueAliplayerV2.getCurrentTime();
            }
          }
        });
      });
    },
    // 视频播放结束
    endedVideo() {
      var that = this;
      clearInterval(this.learnedTimeOut);
      this.updateLearningLog();
      that.course.ListCatalog.map(p => {
        p.ListMaterial.map(n => {
          if (n.Id == this.currentCourse.Id) {
            if (n.Type == "mp4") {
              n.CurrentPosition = 0;
            }
          }
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.detail-top {
  display: flex;
  height: 328px;
  width: 1100px;
  margin: 24px auto;
  background: #ffffff;
  box-sizing: border-box;
  padding: 16px;
  justify-content: center;
  .course-img {
    width: 527px;
    height: 296px;
    border-radius: 8px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .course-cur-bg {
      width: 527px;
      height: 296px;
      background: #333333;
      opacity: 0.5;
      border-radius: 8px;
    }
    .course-living-wrap {
      width: 527px;
      height: 296px;
      border-radius: 8px;
      margin-top: -296px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .living-wrap {
        background: #333333;
        padding: 8px 12px;
        opacity: 0.9;
        display: flex;
        align-items: center;
        border-radius: 4px;
      }
      .course-cur-icon {
        color: #ffffff;
        font-size: 40px;
      }
      .course-cur-text {
        color: #ffffff;
        font-size: 16px;
        margin-left: 8px;
      }
    }
  }
  .course-right {
    display: flex;
    justify-content: space-between;
    margin-left: 24px;
    flex-direction: column;
    padding: 8px 0 16px;
    flex: 1;
    .right-row {
      display: flex;
    }
    .price-text {
      font-size: 32px;
      font-weight: 600;
      color: #ff7946;
      margin-top: 96px;
    }
  }
}
.btf-box {
  display: flex;
  justify-content: center;
}
.detail-bottom {
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
}
.tab-wrap {
  width: 100%;
  min-height: 600px;
  background: #ffffff;
  box-sizing: border-box;
  .tab-header {
    display: flex;
    height: 74px;
    border-bottom: 1px solid #eeeeee;
    .tab-item {
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 4px solid #ffffff;
      margin-left: 24px;
      padding: 0px 13px;
      box-sizing: border-box;
      cursor: pointer;
      &:hover {
        border-bottom: 4px solid #598df3;
        .tab-title {
          color: #598df3;
        }
      }
      .tab-title {
        font-size: 20px;
        font-weight: 400;
        color: #333333;
      }
    }
    .active {
      border-bottom: 4px solid #598df3 !important;
      .tab-title {
        color: #598df3 !important;
        font-weight: 600;
      }
    }
  }
  .tab-content {
    padding: 16px 40px;
  }
}

.course-lecturer {
  display: flex;
  width: 357px;
  height: 479px;
  margin-left: 16px;
  background: #ffffff;
  flex-direction: column;
  .lecturer-header {
    display: flex;
    height: 74px;
    align-items: center;
    border-bottom: 1px solid #eeeeee;
    width: 100%;
    .header-text {
      font-size: 20px;
      margin-left: 24px;
    }
  }
  .lecturer-list {
    display: flex;
    justify-content: center;
    width: 357px;
    .carousel-box {
      width: 293px;
      .lecturer-item {
        background-color: #ffffff;
        width: 293px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .lecturer-img {
          width: 160px;
          height: 160px;
          background: #ececec;
          border-radius: 80px;
          margin-top: 32px;
        }
        .lecturer-name {
          font-size: 20px;
          font-weight: 600;
          color: #333333;
          margin-top: 24px;
        }
        .lecturer-introduction {
          height: 110px;
          font-size: 14px;
          color: #333333;
          margin-top: 8px;
          width: 100%;
        }
      }
    }
    .el-carousel__arrow {
      top: 105px;
    }
  }
}
.item-content {
  .el-collapse {
    border-top: none;
    border-bottom: none;
    .item-catalog {
      font-size: 18px;
      font-weight: 600;
    }
    .small-course {
      border-top: 1px solid #eeeeee;
    }
    .xianxia-course {
      border-top: 1px solid #eeeeee;
    }
  }
  .no-catalog {
    display: flex;
    flex-direction: column;
    .small-course {
      border-bottom: 1px solid #eeeeee;
    }
    .xianxia-course {
      border-bottom: 1px solid #eeeeee;
    }
  }
  .small-course {
    display: flex;
    flex-direction: column;
    // background: rgba(153, 153, 153, 0.08);
    box-sizing: border-box;
    padding: 10px 16px;
    &:hover {
      background-color: rgba(153, 153, 153, 0.08);
      cursor: pointer;
    }
    .name-text {
      color: #333333;
      font-size: 16px;
      font-weight: 600;
    }
    .info-span-b {
      font-size: 16px;
      margin: 0 8px;
    }
    .info-span {
      font-size: 14px;
      color: #999999;
      margin: 0 8px;
    }
    .course-index {
      display: inline-block;
      font-size: 16px;
      color: #999999;
      width: 24px;
    }
    .text-icon {
      font-size: 16px;
      color: #999999;
      margin-right: 4px;
    }
  }
  .xianxia-course {
    display: flex;
    flex-direction: column;
    // background: rgba(153, 153, 153, 0.08);
    box-sizing: border-box;
    padding: 10px 16px;
    .name-text {
      color: #333333;
      font-size: 16px;
      font-weight: 600;
    }
    .info-span-b {
      font-size: 16px;
      margin: 0 8px;
    }
    .info-span {
      font-size: 14px;
      color: #999999;
      margin: 0 8px;
    }
    .course-index {
      display: inline-block;
      font-size: 16px;
      color: #999999;
      width: 24px;
    }
    .text-icon {
      font-size: 16px;
      color: #999999;
      margin-right: 4px;
    }
  }
}
.share-url {
  display: flex;
  margin-bottom: 20px;
}
.share-qr {
  display: flex;
}
.share-input {
  margin-right: 10px;
}
.div-qr {
  padding: 3px 3px 0px 3px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(215, 217, 226, 1);
}
.div-qr-remark {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  justify-content: center;
  align-items: flex-start;
}
.notes-text {
  color: #999999;
  margin-left: 32px;
  font-size: 12px;
}
.user-form >>> {
  .el-form-item {
    margin-bottom: 16px;
  }
}
.dialog-footer {
  display: flex;
  justify-content: space-between;
}
.price-text {
  color: #ff7946;
  font-size: 14px;
}
.material_file_item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
  .material_file_item_content {
    display: flex;
    align-items: center;
    .material_file_title {
      display: flex;
      align-items: center;
      margin-left: 5px;
      font-weight: bold;
    }
  }
  .material_file_item_btn {
    // color: #f56c6c;
  }
}
.div-container {
  overflow: hidden;
}
</style>
